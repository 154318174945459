<template>
  <div class="warning-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>预警管理</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <Row>
          <Col span="24">
            <h2 class="search-title border-bottom">按条件搜索</h2>
          </Col>
        </Row>
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
            <Form-item label="采集时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                <Input v-model="searchForm.memble_name" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师：">
                <Cascader :data="booster" v-model="boosterArr"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="预警描述：">
                <Input v-model="searchForm.key" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
                <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
                <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
                <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <div class="tac">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </div>
        </Form>
      </div>
      <div class="filter-list-tpl">
        <Row style="margin-bottom: 15px;">
          <Col span="24">
            请选择：
            <Radio-group v-model="searchForm.status" @on-change="changeStatus">
              <Radio label="0">全部</Radio>
              <Radio label="1">未处理</Radio>
              <Radio label="2">处理中</Radio>
              <Radio label="3">已处理</Radio>
              <Radio label="4">按名字排序</Radio>
            </Radio-group>
          </Col>
        </Row>
        <Table stripe :columns="columns" :data="warningList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delWarning" v-if="this.power.indexOf('预警管理--删除预警记录的权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
    <!--处理方式modal-->
    <Modal v-model="handlemodal" title="输入处理方式">
      <div class="slotbody">
        <!-- <Input v-model="handleForm.handle_record" type="textarea" :rows="4" placeholder="输入处理方式"></Input> -->
        <Radio-group v-model="handleForm.handle_record">
          <Radio label="电话处理">电话处理</Radio>
          <Radio label="报店内员工处理">报店内员工处理</Radio>
          <Radio label="已经促进处理">已经促进处理</Radio>
        </Radio-group>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okHandle">提交</Button>
      </div>
    </Modal>
    <!--输入反馈情况modal-->
    <Modal v-model="feedbackmodal" title="输入反馈情况">
      <div class="slotbody">
        <Input v-model="handleForm.feedback" type="textarea" :rows="4" placeholder="输入反馈情况"></Input>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okFeedback">提交</Button>
      </div>
    </Modal>
    <!--查看反馈情况modal-->
    <Modal v-model="feeddetailmodal" title="查看反馈情况">
      <div class="slotbody">
        <div class="feed-txt break-word">{{feedDetail}}</div>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okCancel">确定</Button>
      </div>
    </Modal>
    <!--查看处理纪录modal-->
    <Modal v-model="handledetailmodal" title="查看处理纪录">
      <div class="slotbody">
        <div class="feed-txt break-word">{{handleDetail}}</div>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okCancel">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import warningService from '@/services/warningService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				memble_name: '',
				booster: '',
				key: '',
				pid: '',
				cid: '',
				sid: '',
				status: 0,
			},
			del: {
				warning_ids: '',
			},
			handleForm: {
				warning_id: '',
				handle_record: '',
				feedback: '',
			},
			booster: [],
			boosterArr: [],
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '采集时间', width: 180, key: 'info_time', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '会员姓名', key: 'member_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{
					title: '预警问题描述',
					key: 'info',
					align: 'center',
					render: (h, params) => {
						if (params.row.monitor_type === '血压') {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href:
												'#/base/member/detail/' +
												this.warningList[params.index].member_id +
												'/5/Blood/2/' +
												this.warningList[params.index].monitor_id,
										},
									},
									params.row.info,
								),
							]);
						} else if (params.row.monitor_type === '血糖') {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href:
												'#/base/member/detail/' +
												this.warningList[params.index].member_id +
												'/5/BloodSugar/6/' +
												this.warningList[params.index].monitor_id,
										},
									},
									params.row.info,
								),
							]);
						} else if (params.row.monitor_type === '尿酸') {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href:
												'#/base/member/detail/' +
												this.warningList[params.index].member_id +
												'/5/UricAcid/12/' +
												this.warningList[params.index].monitor_id,
										},
									},
									params.row.info,
								),
							]);
						} else if (params.row.monitor_type === '血脂') {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#ff8400',
										},
										attrs: {
											target: '_blank',
											href:
												'#/base/member/detail/' +
												this.warningList[params.index].member_id +
												'/5/Cholesterin/16/' +
												this.warningList[params.index].monitor_id,
										},
									},
									params.row.info,
								),
							]);
						}
					},
				},
				{ title: '促进师', key: 'belong_booster_name', align: 'center' },
				{
					title: '处理记录',
					key: 'handle_record',
					align: 'center',
					render: (h, params) => {
						if (params.row.handle_record === null) {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#ff8400',
										},
										on: {
											click: () => {
												this.goHandle(params.index);
											},
										},
									},
									'立即处理',
								),
							]);
						} else {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#00cb84',
										},
										on: {
											click: () => {
												this.goSeeHandle(params.index);
											},
										},
									},
									'查看',
								),
							]);
						}
					},
				},
				{
					title: '状态',
					key: 'status_string',
					align: 'center',
					render: (h, params) => {
						if (params.row.status_string === '处理中') {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#00cb84',
										},
										on: {
											click: () => {
												this.goFeed(params.index);
											},
										},
									},
									'处理中',
								),
							]);
						} else {
							return h('div', params.row.status_string);
						}
					},
				},
				{
					title: '反馈情况',
					key: 'feedback',
					align: 'center',
					render: (h, params) => {
						if (params.row.feedback) {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#00cb84',
										},
										on: {
											click: () => {
												this.goSee(params.index);
											},
										},
									},
									'查看',
								),
							]);
						} else {
							return h('div', params.row.feedback);
						}
					},
				},
			],
			warningList: [],
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			pageTotal: 0,
			handlemodal: false,
			feedbackmodal: false,
			feeddetailmodal: false,
			handledetailmodal: false,
			power: '',
			feedDetail: '',
			handleDetail: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	watch: {
		boosterArr() {
			this.searchForm.booster = this.boosterArr[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getWarningList();
		warningService.getWarningSelect().then((data) => {
			this.booster = data.booster;
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getWarningList() {
			warningService.getWarningList(this.searchForm).then((data) => {
				this.warningList = data.list;
				if (data.cur_page > data.page_size && data.row_size !== 0) {
					this.pageTotal = data.row_size + 1;
				} else {
					this.pageTotal = data.row_size;
				}
				this.del.warning_ids = '';
			});
		},
		handleSubmit() {
			this.searchForm.page = 1;
			this.getWarningList();
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getWarningList();
		},
		changeStatus() {
			this.searchForm.page = 1;
			this.getWarningList();
		},
		selectOne(selection) {
			let arrId = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].warning_id);
			}
			this.del.warning_ids = arrId.join(',');
		},
		delWarning() {
			if (!this.del.warning_ids) {
				this.$Message.warning('请先选择要删除的预警信息');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选预警信息吗',
					onOk: () => {
						this.okdel();
					},
				});
			}
		},
		okdel() {
			warningService.deleteWarning(this.del).then(() => {
				this.getWarningList();
			});
		},
		goHandle(index) {
			if (this.power.indexOf('预警管理--预警处理的权限') > -1) {
				this.handleForm.warning_id = this.warningList[index].warning_id;
				this.handlemodal = true;
			} else {
				this.$Message.warning('抱歉，你暂没有该权限');
			}
		},
		okHandle() {
			if (!this.handleForm.handle_record) {
				this.$Message.warning('请先填写处理方式');
			} else {
				warningService.handleWarning(this.handleForm).then(() => {
					this.handlemodal = false;
					this.handleForm.handle_record = '';
					this.getWarningList();
				});
			}
		},
		goFeed(index) {
			if (this.power.indexOf('预警管理--输入反馈的权限') > -1) {
				this.handleForm.warning_id = this.warningList[index].warning_id;
				this.feedbackmodal = true;
			} else {
				this.$Message.warning('抱歉，你暂没有该权限');
			}
		},
		okFeedback() {
			if (!this.handleForm.feedback) {
				this.$Message.warning('请先输入反馈情况');
			} else {
				warningService.handleWarning(this.handleForm).then(() => {
					this.feedbackmodal = false;
					this.handleForm.feedback = '';
					this.getWarningList();
				});
			}
		},
		goSee(index) {
			this.feedDetail = this.warningList[index].feedback;
			this.feeddetailmodal = true;
		},
		okCancel() {
			this.feeddetailmodal = false;
			this.handledetailmodal = false;
		},
		goSeeHandle(index) {
			this.handleDetail = this.warningList[index].handle_record;
			this.handledetailmodal = true;
		},
	},
};
</script>

<style lang="css" scoped>
.break-word {
  word-break: break-all;
}
.feed-txt {
  text-indent: 2em;
  line-height: 20px;
}
</style>
